import { useEffect, useState } from "react";

export function pad(num: number | string, size: number, ext: string = "0") {
  if (!(typeof num === "string")) num = num.toString();
  while (num.length < size) num = ext + num;
  return num;
}

export default function useTimeUntil(date: Date | number) {
  const targetTime = typeof date === "number" ? date : date.getTime();
  const [currentTime, setCurrentTime] = useState(new Date().getTime());

  useEffect(() => {
    const intervalId = setInterval(() => {
      const date = new Date();
      setCurrentTime(date.getTime());
    }, 100);
    return () => clearInterval(intervalId);
  }, [date]);

  const diff = targetTime - currentTime;
  const due = diff < 0 ? true : false;

  const totalMs = Math.abs(diff);
  const totalSeconds = Math.floor(totalMs / 1000);
  const totalMinutes = Math.floor(totalSeconds / 60);
  const totalHours = Math.floor(totalMinutes / 60);

  const ms = totalMs % 1000;
  const seconds = totalSeconds % 60;
  const minutes = totalMinutes % 60;
  return { due, totalHours, minutes, seconds, ms };
}
