import { z } from "zod";
import location from "./location";
import season from "./season";

const performance = z.object({
  id: z.string(),
  date: z.string().datetime().pipe(z.coerce.date()),
  omitted: z.boolean(),
  location,
  season,
});

export type Performance = z.output<typeof performance>;
export default performance;
