import { use, useEffect } from "react";
import "./Countdown.css";
import useTimeUntil, { pad } from "./hooks/useTimeUntil";
import type { Performance } from "./schema/performance";

export default function Countdown({performance: performancePromise}: {performance: Promise<Performance> }) {
  const performance = use(performancePromise);
  useEffect(()=>console.log("Performance:", performance), [performance])
  const { due, totalHours, minutes, seconds } = useTimeUntil(performance.date);
  const timer = `${pad(totalHours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`;
  return (
    <>
      <video id="bgVideo" controls={false} preload="true" autoPlay loop muted>
        <source src="logoloop.mp4" type="video/mp4" />
      </video>
      <div
        className="main"
        style={{ backgroundColor: due ? "transparent" : undefined }}
      >
        {due ? null : <div className="centered">{timer}</div>}
      </div>
    </>
  );
}
